import React, { useState } from "react";
import {
  createStyles,
  Header,
  Container,
  Group,
  Burger,
  Paper,
  Transition,
  TextInput,
  Code,
  Text,
} from "@mantine/core";
import { IconSearch } from "@tabler/icons";
import { useDisclosure, useWindowScroll } from "@mantine/hooks";
import { useSpotlight } from "@mantine/spotlight";
import { MantineLogo } from "@mantine/ds";
import Provider from "./Provider";
import Link from "next/link";
import { useRouter } from "next/router";

import styles from "../styles/nav.module.scss";
import mix from "../utils/mixclass";
import { Longo } from "./Branding";
import Minecraft from "../assets/fonts/Minecraft";

const HEADER_HEIGHT = 60;

const useStyles = createStyles((theme) => ({
  dropdown: {
    position: "absolute",
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: "hidden",

    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
    transition: "all 256ms ease",
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color: theme.colors.gray[2],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor: theme.colors.gray[9],
    },

    [theme.fn.smallerThan("sm")]: {
      borderRadius: 0,
      padding: theme.spacing.md,
    },
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.colors.gray[8],
      color: "#fff",
    },
  },
}));

type Link = {
  link: string;
  label: string;
  // links?: Link[]
};

interface HeaderResponsiveProps {
  links: Link[];
  search?: boolean;
}

function SpotlightSearch() {
  const spotlight = useSpotlight();
  return (
    <TextInput
      placeholder="Search"
      size="xs"
      icon={<IconSearch size={12} stroke={1.5} />}
      w={150}
      readOnly
      onFocus={(e) => e.preventDefault()}
      onClick={() => spotlight.openSpotlight()}
      rightSectionWidth={25}
      rightSection={
        <Code
          fs="sm"
          // p="sm"
          // className={classes.searchCode}
        >
          /
        </Code>
      }
      styles={{ rightSection: { pointerEvents: "none" } }}
    />
  );
}

export default function Nav({ links, search }: HeaderResponsiveProps) {
  const [opened, { toggle, close }] = useDisclosure(false);
  const [active, setActive] = useState(links[0].link);
  const { classes, cx } = useStyles();
  const router = useRouter();
  const [scroll] = useWindowScroll();

  const items = links.map((link) => (
    <Link
      key={link.link}
      href={link.link}
      className={cx(classes.link, {
        // [classes.linkActive]: router.pathname === link.link,
      })}
    >
      {link.label}
    </Link>
  ));

  const notTop = scroll.y > 0;

  return (
    <Header
      height={notTop ? 50 : 75}
      className={mix([styles.root, notTop && styles.solid])}
    >
      <Container className={classes.header}>
        <div className={mix([Minecraft.className, styles.brand])}>
          <Longo
            size={80}
            className={mix([
              styles.mascot,
              !notTop && styles.top,
              styles.smooth,
            ])}
          />
          <span
            className={mix([
              styles.brandName,
              !notTop && styles.top,
              styles.smooth,
            ])}
          >
            ThiccMC
          </span>
        </div>
        <Group spacing={5} className={classes.links}>
          {items}
          {search && <SpotlightSearch />}
        </Group>

        <Burger
          opened={opened}
          onClick={toggle}
          className={classes.burger}
          size="sm"
        />

        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items}
            </Paper>
          )}
        </Transition>
      </Container>
    </Header>
  );
}
