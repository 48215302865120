import localFont from "@next/font/local";
const Minecraft = localFont({
  src: [
    {
      path: "./Minecraft-Regular.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "./Minecraft-Italic.woff2",
      weight: "400",
      style: "italic",
    },
    {
      path: "./Minecraft-Bold.woff2",
      weight: "700",
      style: "normal",
    },
    {
      path: "./Minecraft-BoldItalic.woff2",
      weight: "700",
      style: "italic",
    },
  ],
});
export default Minecraft;
