import Image from "next/image";
import alynaaChibi from "../assets/images/artworks/alynaaChibi.png";
import mix from "../utils/mixclass";
export function Longo({
  size,
  className,
}: {
  size?: number;
  className?: string;
}) {
  return (
    <Image
      src={alynaaChibi}
      className={mix([className])}
      alt="Alynaa the mascot in chibi character style"
      width={size}
      height={size}
    />
  );
}
