import Head from "next/head";
import Nav from "./Nav";
import { ReactNode } from "react";
import { Container } from "@mantine/core";

import styles from "../styles/layout.module.scss";
import Banner from "./Banner";
import { DefaultSans } from "../assets/fonts/GoogleFonts";
import mix from "../utils/mixclass";

export default function Layout({ children }: { children?: ReactNode }) {
  return (
    <>
      <Head>
        <title>Create Next App</title>
        <meta name="description" content="Generated by create next app" />
      </Head>
      <main className={mix([styles.wrapper, DefaultSans.className])}>
        <Nav
          links={[
            { link: "/", label: "Home" },
            { link: "/players", label: "Players" },
            { link: "/wiki", label: "Wiki" },
          ]}
          search={true}
        />
        <Container className={styles.container}>
          <Banner />
          {children}
        </Container>
      </main>
    </>
  );
}

export const pane = styles.pane;
