import Minecraft from "../assets/fonts/Minecraft";
import styles from "../styles/banner.module.scss";
import mix from "../utils/mixclass";
import { pane } from "./Layout";

export default function Banner() {
  return (
    <section id="welcome">
      <div className={mix([styles.banner, pane])}>
        <div className={styles.content}>
          <h5 className={styles.welcome}>Welcome to</h5>
          <h2 className={mix([Minecraft.className, styles.lg_brandname])}>
            ThiccMC
          </h2>
          <h5 className={Minecraft.className}>
            IP:{" "}
            <a className={styles.ip} href={undefined}>
              ThiccMC.com
            </a>
            {" [1.16 - 1.19]"}
          </h5>
        </div>
      </div>
    </section>
  );
}
